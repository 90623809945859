
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { HeaderMainActions, mainActions, } from 'components/app-bar/header-actions';
import { GreetingTextOfAppBar } from 'components/app-bar/header-text';
import ClaimCard from 'components/card/claim-card';
import CmsCard from 'components/card/cms-card';
import OpenQuoteCard from 'components/card/open-quote-card';
import PolicyCards from 'components/card/policy-cards';
import ContactIcon from 'components/icons/contact-icon';
import InsuranceIcon from 'components/icons/insurance-icon';
import PatchIcon from 'components/icons/patch-icon';
import CmsCardDetailsModal from 'components/modal/cms-card-details-modal';
import { useClaims } from 'hooks/api/useClaims';
import { useTranslation } from 'hooks/support/useTranslation';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { ReactNode, useContext, useState } from 'react';
import { getCustomerTypeFromSession } from 'utility/customerTypes';
import { Claim } from 'utility/node/claim/claim.model';
import { getDefaultServerProps } from 'utility/node/defaultServerProps';
import { CmsRelevantForYouCard } from 'utility/node/sanity/relevant-for-you-cards.model';
import { getRelevantCards } from 'utility/node/sanity/sanity';
import { Status as ClaimStatus } from 'utility/node/tia-rest/model/response/claim.response.model';
import { Route } from 'utility/routes';
import { BrandContext } from './_app';
import { isBrowser } from 'utility/common';
const getOfferCard = () => {
    const quoteCard = <OpenQuoteCard key="quotes-card"/>;
    if (!quoteCard) {
        return [];
    }
    return quoteCard;
};
const getClaimCards = (claims: Claim[]): JSX.Element[] => {
    if (!claims) {
        return [];
    }
    return claims
        .filter((claim) => claim.status == ClaimStatus.OPEN ||
        claim.status == ClaimStatus.RE_OPENED)
        .map((claim, index) => {
        return (<Grid item xs={12} sm={6} lg={4} key={`claim-${claim.claimCaseNo}`}>
          <ClaimCard claim={claim} key={index}/>
        </Grid>);
    });
};
export function Index({ relevantCmsCards, isApp, }: {
    relevantCmsCards: CmsRelevantForYouCard[];
    isApp: boolean;
}) {
    const router = useRouter();
    if (isApp && isBrowser()) {
        router.push(Route.APP);
    }
    const { data: session } = useSession();
    const { t } = useTranslation('home');
    const { claims } = useClaims();
    const brand = useContext(BrandContext);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [showCardDetailsModal, setShowCardDetailsModal] = useState(true);
    const [cardSlug, setCardSlug] = useState<string | undefined>(undefined);
    const getCmsRelevantCards = (cmsRelevantCards: CmsRelevantForYouCard[], isDesktop): JSX.Element[] => {
        if (!cmsRelevantCards) {
            return [];
        }
        const cardsToShow = isDesktop ? 3 : 2;
        return cmsRelevantCards.slice(0, cardsToShow).map((cmsCard, index) => (<CmsCard showContent={false} slug={cmsCard.slug} onClick={!!cmsCard.modalContent
                ? () => {
                    setCardSlug(cmsCard.slug);
                    setShowCardDetailsModal(true);
                }
                : null} cmsCard={cmsCard} key={cmsCard.slug}/>));
    };
    const mainActions: mainActions[] = [
        {
            icon: (<PatchIcon width={isDesktop ? 43 : 27} height={isDesktop ? 43 : 27}/>),
            text: t('mainActions.fileClaim'),
            key: 1,
            path: Route.FILE_CLAIM,
        },
        {
            text: t('mainActions.myInsurances', true),
            icon: (<InsuranceIcon width={isDesktop ? 43 : 27} height={isDesktop ? 43 : 27}/>),
            key: 2,
            path: Route.MY_INSURANCES,
        },
        {
            text: t('mainActions.contactUs'),
            icon: (<ContactIcon width={isDesktop ? 43 : 27} height={isDesktop ? 43 : 27}/>),
            key: 3,
            path: Route.CONTACT,
        },
    ];
    return (<>
      <CmsCardDetailsModal open={showCardDetailsModal && !!cardSlug} onClose={() => setShowCardDetailsModal(false)} cmsData={relevantCmsCards.find((c) => c.slug === cardSlug)} slug={cardSlug}/>
      <GreetingTextOfAppBar text={t('greeting', true, {
            name: session?.user.firstName || session?.user.name,
        })}/>

      <HeaderMainActions mainActions={mainActions}/>
      <Box sx={{ pt: 2 }}/>
      <Grid container rowSpacing={3} spacing={3} justifyContent="center">
        {[...getCmsRelevantCards(relevantCmsCards, isDesktop)].map((card, index) => (<Grid item xs={12} sm={6} lg={4} key={index}>
              {card}
            </Grid>))}
      </Grid>
      <Grid container rowSpacing={3} spacing={3} justifyContent="center" sx={{ mt: 0 }}>
        {[...getClaimCards(claims), getOfferCard()].map((card) => {
            return card;
        })}
      </Grid>
      <Box sx={{ py: 2 }}/>
      <PolicyCards totalPriceSection={false} insuranceTitle={t('yourInsurances.title', true)} swipeableInMobileView/>
    </>);
}
async function getServerSideProps(ctx) {
    const defaultServerProps = await getDefaultServerProps(ctx);
    let cards = await getRelevantCards(defaultServerProps.brandDetails.brandAbbreviation, getCustomerTypeFromSession(defaultServerProps.session));
    return {
        props: {
            ...defaultServerProps,
            relevantCmsCards: cards,
        },
    };
}
Index.extendedHeader = true;
Index.disableBreadcrumb = true;
Index.title = 'Min side';
export default Index;

    async function __Next_Translate__getServerSideProps__18fc84758d3__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18fc84758d3__ as getServerSideProps }
  