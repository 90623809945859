import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import CheckCircle from '@mui/icons-material/CheckCircleOutlined';
import { Chip, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'hooks/support/useTranslation';
import { useSWRConfig } from 'swr';
import { prefetchClaimTimeline } from 'hooks/api/useClaimTimeline';
import { prefetchPolicy, usePolicy } from 'hooks/api/usePolicy';
import { getShortDate } from 'utility/date';
import { Claim } from 'utility/node/claim/claim.model';
import { Status } from 'utility/node/tia-rest/model/response/claim.response.model';
import { getClaimDetailsRoute } from 'utility/routes';
import PatchIcon from '../icons/patch-icon';
import Card, { ICard } from './card';

const ClaimCard = ({ claim }: { claim: Claim }) => {
  const { t } = useTranslation('claim');
  const { cache } = useSWRConfig();
  const { policy } = usePolicy(claim?.policyNo, !!claim);

  const title = t(`card.title`, false, {
    date: getShortDate(claim.incidentDate),
  });
  const status = t(
    `status.${claim.status}`,
    false,
    {},
    { fallback: 'Unknown' }
  );

  const subHeader = t(
    `subHeader`,
    false,
    {
      description: claim.causeTypeDescription
        ? `${claim.causeTypeDescription} / ${claim.eventTypeDescription}`
        : claim.eventTypeDescription,
    },
    { fallback: '' }
  );
  const subHeader2 = policy ? (
    t(
      `subHeader2`,
      false,
      {
        productName: policy?.metadata.name,
        policyShortDesc: policy?.policyLines[0].shortDescription,
      },
      { fallback: '' }
    )
  ) : (
    <Skeleton height={20} width="80%" />
  );

  const preFetchClaimDetails = (claim: Claim) => {
    prefetchClaimTimeline(claim.claimCaseNo, cache);
    prefetchPolicy(claim.policyNo, cache);
  };

  const getClaimColor = () => {
    switch (claim.status) {
      case Status.OPEN:
      case Status.RE_OPENED:
        return {
          chip: 'info',
          icon: 'info.light',
          logo: <AccessTimeOutlined />,
        };
      case Status.DECLINED_AND_CLOSED:
      case Status.OPENED_IN_ERROR_AND_CLOSED:
      case Status.NOTIFIED:
      case Status.CLOSED:
        return {
          chip: 'text.primary',
          icon: 'secondary.dark',
          logo: <CheckCircle />,
        };
      default:
        return {
          chip: 'text.primary',
          icon: 'secondary.dark',
          logo: <CheckCircle />,
        };
    }
  };

  const color = getClaimColor();

  const card: ICard = {
    header: {
      title: title,
      subHeader: subHeader,
      subHeader2: subHeader2,
      badge: {
        element: (
          <Typography color={`${color.icon}`} sx={{ mt: -2, ml: -2 }}>
            <PatchIcon height={110} width={110} />
          </Typography>
        ),
        status: (
          <Chip
            sx={{
              backgroundColor: `${color.icon}`,
              color: `${color.chip}.contrastText`,
              '.MuiChip-icon': {
                color: `${color.chip}.contrastText`,
              },
            }}
            size="small"
            label={status}
            variant="filled"
            key="1"
            icon={color.logo}
          />
        ),
      },
    },
    elementKey: 'claimCard-' + claim.claimCaseNo,
    href: getClaimDetailsRoute(claim.claimCaseNo),
    onMouseEnterActionArea: () => preFetchClaimDetails(claim),
    cardType: 'claim',
    logObject: { claimCaseNo: claim.claimCaseNo, claimStatus: claim.status },
  };

  return <Card {...card} />;
};

export default ClaimCard;
