// Generated by https://quicktype.io

export interface ITiaClaimCase {
  claimCaseNo: number;
  claimEventNo: number;
  description?: string;
  partyId: number;
  policyLineNo: number;
  policyLineId: number;
  riskNo: number;
  subriskNo: number;
  claimClass: string;
  questionStatus: QuestionStatus;
  status: Status;
  informerType: InformerType;
  handler: string;
  lossOfBonus: LossOfBonus;
  notificationDate: Date;
  firstOpenDate: Date;
  firstCloseDate?: Date;
  objectNo: number;
  policyObjectId: number;
  objectId?: string;
  closeCode?: CloseCode;
  claimCaseNoAlt: string;
  claimCaseNoSort: string;
  deductible?: number;
  claimBusinessStatus?: ClaimBusinessStatus;
  responsibilityPercent?: string;
  questionClass?: string;
  itemClass?: string;
  reopenDate?: Date;
  recloseDate?: Date;
  tasklistClass?: string;
  informerName?: string;
}

export interface ITiaClaimEvent {
  claimEventNo: number;
  eventType: string;
  causeType: string;
  incidentDate: Date;
  isExactIncidentDate: boolean;
  placeDescription: string;
  createDate: Date;
  cashCall: boolean;
}

export enum CloseCode { //xla_ref CLA_CLOSE_CODE
  AUTOMATED_CLOSURE = 'AUT', //Automatisk lukning af skade
  MANUEL_CLOSURE = 'MAN', //Manuel lukning af skade
}

export enum InformerType { //xla_ref CLA_INFORMER_TYPE
  ADVOKAT = 'ADV',
  ANDRE = 'AND',
  GLASSKADE = 'CGL',
  AUTO_DAH = 'DAH',
  EDI = 'EDI',
  AEGTEFAELLE_SAMLEVER = 'EGT',
  ANDEN_FAMILIE = 'FAM',
  FORSIKRET = 'FTG',
  FORSIKRINGSTAGER = 'IN',
  MODPART = 'MOD',
  REPARATOR = 'REP',
  ROBOT = 'ROB',
  SKADELIDTE = 'SKL',
  TAKSATOR = 'TAK',
  MIGRERET = 'XNI',
}

export enum LossOfBonus { //xla_ref CLA_LOSS_OF_BONUS_TYPE
  YES = 'Y', //Ja
  NO = 'N', //Nej
}

export enum QuestionStatus {
  NOT_COMPLETE = 'NC',
  OK = 'OK',
}

export enum Status { //xla_ref CLA_STATUS
  CLOSED = 'CL', //Lukket
  DECLINED_AND_CLOSED = 'DC', //Afvist og lukket
  OPENED_IN_ERROR_AND_CLOSED = 'EC', //Fejlanlagt
  NOTIFIED = 'NO', //*** MÅ IKKE BRUGES ***
  OPEN = 'OP', //Åben
  RE_OPENED = 'RO', //Genåbnet
}

export enum ClaimBusinessStatus { //xla_ref CLA_BUSINESS_STATUS
  AFSLUTTET_MED_BETALING = '01A',
  ANKET_SJF = '04A',
  ANKET_SKADELIDTE = '05A',
  HENLAGT_BOERNETANDSKADE = '06A',
  HENLAGT_ARBEJDSSKADE = '07A',
  LOBENDE_ERSTATNING = '09A',
  AFSLUTNINGSBREV_EJ_SENDT = 'ABES',
  AFVENTER = 'AFV',
  AFVENTER_ANMELDELSE = 'AFVAN',
  AFVENTER_LUKNING = 'AFVLU',
  AFVENTER_REGNING = 'AFVRE',
  ANDET_KENDT_UDFALD = 'AKE',
  AFSLUTTET_MED_UDBETALING = 'AMU',
  AFVIST = 'AVI',
  BRANDAFTALEN = 'BRA',
  DOBBELTFORSIKRING = 'DOB',
  EAL_19_ELLER_24 = 'EAL',
  FEJLREGISTRERET = 'FEJL',
  FORAELDET = 'FOR',
  GENOPTAGET = 'GEN',
  HENLAGT1 = 'HE',
  HENLAGT2 = 'HEN',
  KAPITEL_2_AFTALEN = 'KAP2',
  OBJEKTIVT_ANSVAR_PERSONSKADE_ = 'OAP',
  OMTEGNET = 'OMT',
  PRO_RATA_ERSTATNING = 'PRO',
  REGRESAFTALENS_PKT_4_4 = 'RAL',
  REGRES = 'REG',
  RUDEREPARATION = 'REP',
  REGRESFRAFALDSAFTALEN = 'RFA',
  RUDEUDSKIFTNING___ = 'RUF',
  ERSTATNING_UNDER_SELVRISIKO = 'SEL',
  SVIG_INGEN_ERSTATNING = 'SIG',
  UNDER_BEHANDLING = 'UBE',
  UDBETALT = 'UDB',
  AFSLUTTET_UDEN_UDBETALING = 'UDE',
  UKENDT_UDFALD = 'UKE',
}
