import useSWR from 'swr';
import { Claim } from 'utility/node/claim/claim.model';
import { ApiRoute } from 'utility/node/routes';

export const useClaims = () => {
  const { data, error, mutate } = useSWR<Claim[]>(ApiRoute.CLAIMS);

  return {
    claims: data,
    claimsErrors: error,
    mutateClaims: mutate,
  };
};
