import useSWR, { Cache, mutate } from 'swr';
import { fetcherWithoutWrapper } from 'utility/api';
import { TimelineEvent } from 'utility/node/claim/timeline-event.model';
import { getClaimTimelineRoute } from 'utility/node/routes';
import { SentryGroup, addMessageWithGroup } from 'utility/sentry';

export const useClaimTimeline = (claimCaseNo: number) => {
  const { data, error, mutate } = useSWR<TimelineEvent[]>(
    getClaimTimelineRoute(claimCaseNo)
  );

  return {
    claimTimeline: data,
    claimTimelineErrors: error,
    mutateClaimTimeline: mutate,
  };
};

export const prefetchClaimTimeline = (
  claimCaseNo: number,
  cache: Cache<any>
) => {
  const timelineSwrKey = getClaimTimelineRoute(claimCaseNo);
  if (!cache.get(timelineSwrKey)) {
    mutate(timelineSwrKey, fetcherWithoutWrapper(timelineSwrKey, {})).catch(
      (e) => {
        addMessageWithGroup({
          group: SentryGroup.claim,
          message: 'Error when prefetching claim timeline',
          printToConsole: true,
          consoleMessage: e,
          tag: 'prefetchClaimTimeline',
        });
      }
    );
  }
};
